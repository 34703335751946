import React, { useEffect, useState } from 'react';
import mqtt from 'mqtt';
import {  Col, Flex, Row, Tree, Typography } from 'antd';
import {Box, Grid} from '@mui/material'
import './Proveit.css'
import { Content } from 'antd/es/layout/layout';
import { tree } from 'd3';
const { DirectoryTree } = Tree;
const {Title} = Typography;




const Proveit = () => {
  const [mqttMessage, setMqttMessage] = useState({'Tulip_Experience_Center':{}});
  const [allMessages, setAllMessages] = useState({}); // New state to store all messages by topic
  const [isConnected, setIsConnected] = useState(false);
  const [antTree, setAntTree] = useState([]);
  const [hotTopic, setHotTopic] = useState(); // For tracking blinking keys
  const [expandedKeys, setExpandedKeys] = useState([]); // State to manage expanded keys
  const topic = `Enterprise/tulip/#`;


  function isEmptyObject(obj) {
    return Object.keys(obj).length === 0 && obj.constructor === Object;
  }

  // Function to update the existing message tree with new data
  const updateMessageTree = (prevMessages, newMessage) => {
    console.log(newMessage)
    return { ...prevMessages, ...mergeDeep(prevMessages, newMessage) }; // Merge new message into the existing tree
  };
  const applyBlinkingEffect = (topicKey) => {
    // Find all elements that have a `data-topic` attribute
    const allElements = document.querySelectorAll('[data-topic]');
    // Iterate over the list of elements to find the one with the matching topic
    allElements.forEach((element) => {
      if (element.dataset.topic.toLowerCase() === topicKey.toLowerCase()) {
        element.classList.add('blinking'); // Add the blinking class
        setHotTopic(topicKey.toLowerCase())
  
        // Remove the class after a delay
        setTimeout(() => {
          element.classList.remove('blinking');
          setHotTopic(null)
        }, 2000); // Duration of the blink animation
      }
    });
  };
  
  const handleTreeClick = (keys, info) => {
    console.log('Trigger Select', keys, info);
  };
  const convertToTreeData = (obj, parentKey = '0', keyCounter = { current: 0 }) => {
    const treeNodes = [];

    for (const key in obj) {
        if (obj.hasOwnProperty(key)) {
            const nodeKey = `${parentKey}-${keyCounter.current}`;
            const nodeTitle = key;
            const children = obj[key];

            // Skip empty objects
            if (typeof children === 'object' && children !== null && Object.keys(children).length === 0) {
                continue;
            }

            const treeNode = {
                title: nodeTitle,
                key: nodeKey,
                dataTopic: children._topic,
            };

            if (children && children._topic) {
                treeNode['data-topic'] = children._topic;
            }

            if (children && children.Value !== undefined && children.Value !== null) {
                let valueStr;
                if (typeof children.Value === 'object') {
                    const jsonString = JSON.stringify(children.Value);
                    valueStr = jsonString.length > 50 ? jsonString.substring(0, 50) + "..." : jsonString;
                } else {
                    valueStr = children.Value.toString();
                }

                treeNode.title = (
                    <span>
                        <span>{nodeTitle}: </span>
                        <span className="node-value">{valueStr}</span>
                    </span>
                );
                treeNode.isLeaf = true;
                treeNodes.push(treeNode);
            } else if (typeof children === 'object' && children !== null) {
                const childNodes = convertToTreeData(children, nodeKey, keyCounter);
                if (childNodes.length > 0) {
                    treeNode.children = childNodes;
                    treeNodes.push(treeNode);
                }
            } else {
                treeNode.isLeaf = true;
            }

            keyCounter.current++;
        }
    }

    return treeNodes;
};


  // Recursive function to merge two objects deeply
  const mergeDeep = (target = {}, source = {}) => {
    for (const key in source) {
      if (source.hasOwnProperty(key)) {
        // If the source value is an object and the target has that key
        if (source[key] instanceof Object && key in target) {
          if (Object.keys(source[key]).length === 0) {
            // If source is an empty object, remove the key from target
            delete target[key];
          } else {
            // Recursively merge non-empty objects
            target[key] = mergeDeep(target[key], source[key]);
          }
        } else {
          // Otherwise, set or replace the value in the target
          target[key] = source[key];
        }
      }
    }
    return target; // Return the updated target
  };
  
  const structureMessageByTopic = (topic, msg) => {
    const levels = topic.split('/'); // Split topic into levels
    const nestedMessage = levels.reduceRight((acc, level) => {
      if (acc && typeof acc === 'object' && Object.keys(acc).length !== 0) {
        // If it's the last level (the one containing actual data), add the topic
        if (levels[levels.length - 1] === level) {
          acc['_topic'] = topic; // Set the topic only at the final level
        }
      }

      return { [level]: acc }; // Build the hierarchy
    }, msg);
    return nestedMessage; // Return the nested message
  };
  

  useEffect(() => {
    // Connect to the HiveMQ broker
        const client = mqtt.connect('wss://a2417032a8f1427685fda167966172a5.s2.eu.hivemq.cloud:8884/mqtt', {
          username: 'hivemq.webclient.1693222052513',
          password: '7P&TLW3Qf.k0am5d*V#t', 
          rejectUnauthorized: false, // Set to false for self-signed certs (not recommended for production)
    });
    setMqttMessage({});
    setAntTree([]);
    setAllMessages({});
    // On connection
    client.on('connect', () => {
      //console.log('Connected to MQTT broker');
      setIsConnected(true);
      
      // Subscribe to a topic
      client.subscribe(topic, (err) => {
        if (!err) {
          console.log(`Subscribed to topic: ${topic}`);
        } else {
          console.error('Subscription error: ', err);
        }
      });
    });
    client.on('message', (topic, message) => {
        let msg = {};
        try {
            msg = { data: JSON.parse(message.toString()), topic: topic };
        } catch (error) {
            //console.log(topic, message)
            console.error("Failed to parse JSON:", error, "Message:", message.toString());
            return;
        }
        const tree = structureMessageByTopic(topic, msg.data);
        
        // Normalize topic to lowercase
        const topicKey = topic; 
        const newValue = msg.data.Value; // Get the new value from the message
        
        // Update allMessages state
        setAllMessages(prev => {
          const oldMessage = prev[topicKey]; // Get the previous message for comparison
      
          // Check if this topic already exists in oldMessage
          if (!oldMessage) {
            //console.log('new message entirely');
            //console.log(mqttMessage)
             applyBlinkingEffect(topicKey); 
            // If there's no previous message, it's a new topic, so we add it to blinkingKeysy
          } else if (oldMessage.Value !== newValue) {
            //console.log(oldMessage.Value, newValue, topicKey)
            applyBlinkingEffect(topicKey); 
          }
          // Now, update the message for this topic
          const updatedMessages = { ...prev, [topicKey]: msg.data }; // Update to latest message
          // Set a timer to clear the blinking key after a short duration Adjust the time as needed
      
          return updatedMessages; // Return the updated messages state
        });
        
        // Update mqttMessage state
        setMqttMessage(prevMessages => updateMessageTree(prevMessages, tree));
        
        // Convert the new tree directly from the merged result
        const newAntTree = convertToTreeData(updateMessageTree(mqttMessage, tree));
        setAntTree(newAntTree);
        
        // Set all keys as expanded
        const allKeys = [];
        const flattenKeys = (nodes) => {
          nodes.forEach(node => {
            allKeys.push(node.key);
            if (node.children) {
              flattenKeys(node.children);
            }
          });
        };
        flattenKeys(newAntTree);
        setExpandedKeys(allKeys);
      });
      
      
      

    // Handle connection errors
    client.on('error', (err) => {
      console.error('Connection error: ', err);
    });

    // Cleanup connection on unmount
    return () => {
      client.end();
    };
  }, []); // Add mqttMessage as a dependency to rerun effect when it updates


  return (
    <Box sx={{background:`linear-gradient(to right, #00263e, #003b71)`, height:'100vh'}}>
        <Row justify="space-between" className='antContainer' 
        sx={{height:'100%'}}>
        <Col
        span={6} // Fixed size for tree column
        className={'unsFlex'} sx={{height:'100%'}}
        >
            <Flex 
            vertical>
            <Title level={5} style={{color:'#F2EEA1', marginTop:'5px'}}>Unified Name Space</Title>
                
                <Content className='customScroll' >
                    <div style={{flex:"1 1 auto", maxHeight:"100%"}}>
                    <DirectoryTree 
                        treeData={antTree} 
                        expandedKeys={expandedKeys} 
                        showLine={true}
                        showIcon={false}
                        className={'tree'}
                        selectable={false}
                        //onSelect={handleTreeClick}
                    />
                    </div>
                 </Content>
        </Flex>
        </Col>
      <Col
      flex="1"
      >

      </Col>
        </Row>
        </Box>
  );
};

export default Proveit;
